import { ThemeOverride } from '@chakra-ui/react';
import Alert from './components/Alert';
import Avatar from './components/Avatar';
import Badge from './components/Badge';
import Button from './components/Button';
import IconBadge from './components/IconBadge';
import Input from './components/Input';
import Link from './components/Link';
import Menu from './components/Menu';
import Modal from './components/Modal';
import Pagination from './components/Pagination';
import Select from './components/Select';
import Tabs from './components/Tabs';
import Tooltip from './components/Tooltip';
import { breakpoints } from './foundations/breakpoints';
import { colors } from './foundations/colors';
import { radii } from './foundations/radii';
import { shadows } from './foundations/shadows';
import { sizes } from './foundations/sizes';
import { space } from './foundations/space';
import { typography } from './foundations/typography';
import { zIndices } from './foundations/zIndices';
import { styles } from './styles';

export const theme: ThemeOverride = {
   styles,
   breakpoints,
   colors,
   radii,
   shadows,
   sizes,
   space,
   ...typography,
   zIndices,
   components: {
      Alert,
      Avatar,
      Badge,
      Button,
      IconBadge,
      Input,
      Link,
      Menu,
      Modal,
      Pagination,
      Select,
      Tabs,
      Tooltip,
   },
};

export default theme;
